import React from 'react'
import styled from 'styled-components'
import { ContainerMob } from './../../../utils/utils'

const Stopka = styled.div`
    padding-bottom: 1rem;
    text-align: center;
    background-color: ${props => props.bgColor || props.theme.color.white};

    p {
        opacity: .5;
        font-weight: 300;
        font-size: 15px;
        line-height: 1;
        margin-bottom: .3rem;
    }
`;

const FooterIks = (props) => {

    return (
        <Stopka
            bgColor={props.bgColor}
            hrOpacity={props.hrOpacity}>
            <ContainerMob>
                <p>{new Date().getFullYear()} © Wszystkie prawa zastrzeżone</p>
            </ContainerMob>
        </Stopka>
    )
}
export default FooterIks