const colorVar = {
    hueGrey: 220,

    base: {
        h: 350,
        s: 60,
        l: 50,
    },

    prim: {
        h: 340,
        s: 100,
        l: 20,
    },

    sec: {
        h: 260,
        s: 93,
        l: 30,
    },
}

export const color = {
    font: 'rgba(0, 0, 0, .80)',
    heading: 'rgba(0, 0, 0, .80)',
    white: 'rgba(255, 255, 255, 1)',

    grey1: `hsl(${colorVar.hueGrey}, 0%, 10%)`,
    grey2: `hsl(${colorVar.hueGrey}, 0%, 20%)`,
    grey3: `hsl(${colorVar.hueGrey}, 0%, 30%)`,
    grey4: `hsl(${colorVar.hueGrey}, 0%, 40%)`,
    grey5: `hsl(${colorVar.hueGrey}, 0%, 50%)`,
    grey6: `hsl(${colorVar.hueGrey}, 0%, 65%)`,
    grey7: `hsl(${colorVar.hueGrey}, 0%, 80%)`,
    grey8: `hsl(${colorVar.hueGrey}, 0%, 90%)`,
    grey9: `hsl(${colorVar.hueGrey}, 0%, 95%)`,

    hue: '22',

    base1: `hsl(${colorVar.base.h}, ${colorVar.base.s}%, ${colorVar.base.l}%)`,
    base2: `hsl(${colorVar.base.h}, ${colorVar.base.s}%, ${colorVar.base.l + 10}%)`,
    base3: `hsl(${colorVar.base.h}, ${colorVar.base.s}%, ${colorVar.base.l + 20}%)`,
    base4: `hsl(${colorVar.base.h}, ${colorVar.base.s}%, ${colorVar.base.l + 30}%)`,
    base5: `hsl(${colorVar.base.h}, ${colorVar.base.s}%, ${colorVar.base.l + 40}%)`,
    base6: `hsl(${colorVar.base.h}, ${colorVar.base.s}%, ${colorVar.base.l + 45}%)`,
    base7: `hsl(${colorVar.base.h}, ${colorVar.base.s}%, ${colorVar.base.l + 50}%)`,
    base8: `hsl(${colorVar.base.h}, ${colorVar.base.s}%, ${colorVar.base.l + 70}%)`,
    base9: `hsl(${colorVar.base.h}, ${colorVar.base.s}%, ${colorVar.base.l + 80}%)`,

    prim1: `hsl(${colorVar.prim.h}, ${colorVar.prim.s}%, ${colorVar.prim.l}%)`,
    prim2: `hsl(${colorVar.prim.h}, ${colorVar.prim.s}%, ${colorVar.prim.l + 10}%)`,
    prim3: `hsl(${colorVar.prim.h}, ${colorVar.prim.s}%, ${colorVar.prim.l + 20}%)`,
    prim4: `hsl(${colorVar.prim.h}, ${colorVar.prim.s}%, ${colorVar.prim.l + 30}%)`,
    prim5: `hsl(${colorVar.prim.h}, ${colorVar.prim.s}%, ${colorVar.prim.l + 40}%)`,
    prim6: `hsl(${colorVar.prim.h}, ${colorVar.prim.s}%, ${colorVar.prim.l + 50}%)`,
    prim7: `hsl(${colorVar.prim.h}, ${colorVar.prim.s}%, ${colorVar.prim.l + 60}%)`,
    prim8: `hsl(${colorVar.prim.h}, ${colorVar.prim.s}%, ${colorVar.prim.l + 70}%)`,
    prim9: `hsl(${colorVar.prim.h}, ${colorVar.prim.s}%, ${colorVar.prim.l + 80}%)`,

    sec1: `hsl(${colorVar.sec.h}, ${colorVar.sec.s}%, ${colorVar.sec.l}%)`,
    sec2: `hsl(${colorVar.sec.h}, ${colorVar.sec.s}%, ${colorVar.sec.l + 10}%)`,
    sec3: `hsl(${colorVar.sec.h}, ${colorVar.sec.s}%, ${colorVar.sec.l + 20}%)`,
    sec4: `hsl(${colorVar.sec.h}, ${colorVar.sec.s}%, ${colorVar.sec.l + 30}%)`,
    sec5: `hsl(${colorVar.sec.h}, ${colorVar.sec.s}%, ${colorVar.sec.l + 40}%)`,
    sec6: `hsl(${colorVar.sec.h}, ${colorVar.sec.s}%, ${colorVar.sec.l + 50}%)`,
    sec7: `hsl(${colorVar.sec.h}, ${colorVar.sec.s}%, ${colorVar.sec.l + 60}%)`,
    sec8: `hsl(${colorVar.sec.h}, ${colorVar.sec.s}%, ${colorVar.sec.l + 70}%)`,
    sec9: `hsl(${colorVar.sec.h}, ${colorVar.sec.s}%, ${colorVar.sec.l + 80}%)`,

    opacityBlack06: 'rgba(0, 0, 0, .6)',
}