import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.div`
  width: 100%;
  //padding-right: 15px;
  //padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;
  
  ${down('lg')} {
    max-width: 1200px;
  }

  ${down('md')} {
    max-width: 960px;
  }
  
  ${down('sm')} {
    max-width: 720px;
  }

  ${down('xs')} {
    max-width: 540px;
  }
`;

export const ContainerMob = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;
  
  ${down('lg')} {
    max-width: 1200px;
  }

  ${down('md')} {
    max-width: 960px;
  }
  
  ${down('sm')} {
    max-width: 720px;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  ${down('xs')} {
    max-width: 540px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

export const CardWrapper = styled(Container)`
  display: grid;
  padding-left: 0;
  padding-right: 0;
  grid-column-gap: ${props => props.colGap || '2rem'};
  grid-row-gap: ${props => props.rowGap || '2rem'};
  margin-top: ${props => props.mt || '2rem'};
  margin-bottom: ${props => props.mb || '2rem'};
  padding-top: ${props => props.pt || '0'};
  padding-bottom: ${props => props.pb || '0'};
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(${props => props.xl || 5}, 1fr);

  ${down('lg')} {
    grid-template-columns: repeat(${props => props.l || 4}, 1fr);
  }

  ${down('md')} {
    grid-template-columns: repeat(${props => props.m || 3}, 1fr);
  }

  ${down('sm')} {
    grid-template-columns: repeat(${props => props.s || 2}, 1fr);
  }

  ${down('xs')} {
    grid-template-columns: repeat(${props => props.xs || 1}, 1fr);
  }
`;

export const CardWrapperFull = styled.div`
  display: grid;
  padding-left: 0;
  padding-right: 0;
  grid-column-gap: ${props => props.colGap || '2rem'};
  grid-row-gap: ${props => props.rowGap || '2rem'};
  margin-top: ${props => props.mt || '2rem'};
  margin-bottom: ${props => props.mb || '2rem'};
  padding-top: ${props => props.pt || '0'};
  padding-bottom: ${props => props.pb || '0'};
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(${props => props.xl || 5}, 1fr);

  ${down('lg')} {
    grid-template-columns: repeat(${props => props.l || 4}, 1fr);
  }

  ${down('md')} {
    grid-template-columns: repeat(${props => props.m || 3}, 1fr);
  }

  ${down('sm')} {
    grid-template-columns: repeat(${props => props.s || 2}, 1fr);
  }

  ${down('xs')} {
    grid-template-columns: repeat(${props => props.xs || 1}, 1fr);
  }
`;

export const Section = styled.div`
  padding-top: ${props => props.pt || '5rem'};
  padding-bottom: ${props => props.pb || '5rem'};
  background-color: ${props => props.bgColor || 'transparent'};
`;

export const Logo = styled.div`
    ${down('sm')} {
        grid-column: 1;
        padding: 0 2rem 2rem 2rem;
        ::before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 90px;
            margin: 0px -2rem 2rem -2rem ;
            opacity: .8;
            display: block;
            background-color: ${props => props.bgcolor || props.theme.color.white};
        }
    }
`;