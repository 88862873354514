export const godziny = [
    {
        id: 1,
        day: 'PN',
        godzinaOd: '7:00',
        godzinaDo: '17:00',
    },
    {
        id: 2,
        day: 'WT',
        godzinaOd: '7:00',
        godzinaDo: '17:00',
    },
]

export const info =
{
    ulica: 'Akademicka',
    nrBud: '5 pok.024a',
    kod: '44-100',
    miasto: 'Gliwice',
    woj: 'Śląskie',
    kraj: 'Polska',
    tel: '504 454 730',
    mail: 'fabianaleksandra@gmail.com',
    nip: '646 28 33 128',
    regon: '365516933'
}